define('ember-date-components/components/time-picker-input', ['exports', 'ember'], function (exports, _ember) {
  var get = _ember['default'].get;
  var TextField = _ember['default'].TextField;

  /**
   * This is an extended {{input}} to send appropriate events for arrowUp/arrowDown.
   * It is also slightly changed to send an input-changed event when a key that is neither arrowUp/arrowDown, enter or escape
   * is pressed.
   *
   * @namespace EmberDateComponents
   * @class TimePickerInput
   * @extends Ember.TextField
   * @public
   */
  exports['default'] = TextField.extend({
    classNames: [],

    attributeBindings: ['disabled'],

    type: 'text',

    /**
     * If this is true, the time picker is disabled and the selected time cannot be changed.
     *
     * @attribute disabled
     * @type {Boolean}
     * @default false
     * @public
     */
    disabled: false,

    interpretKeyEvents: function interpretKeyEvents(event) {
      var map = get(this, 'KEY_EVENTS');
      var keyCode = event.keyCode;

      var method = map[keyCode];
      if (method) {
        return this[method](event);
      } else {
        this.inputChange();
      }
    },

    inputChange: function inputChange() {
      this._elementValueDidChange();
      var value = get(this, 'value');
      this.sendAction('input-change', value, this, event);
    },

    arrowUp: function arrowUp(event) {
      this.sendAction('arrow-up', this, event);
    },

    arrowDown: function arrowDown(event) {
      this.sendAction('arrow-down', this, event);
    },

    escape: function escape(event) {
      this.sendAction('escape', this, event);
    },

    enter: function enter(event) {
      this.sendAction('enter', this, event);
    },

    KEY_EVENTS: {
      38: 'arrowUp',
      40: 'arrowDown',
      13: 'enter',
      27: 'escape'
    }
  });
});