define('ember-date-components/components/time-picker', ['exports', 'ember', 'ember-date-components/templates/components/time-picker', 'moment', 'ember-computed', 'ember-date-components/utils/parse-time', 'ember-date-components/utils/build-time-range'], function (exports, _ember, _emberDateComponentsTemplatesComponentsTimePicker, _moment, _emberComputed, _emberDateComponentsUtilsParseTime, _emberDateComponentsUtilsBuildTimeRange) {
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  var Component = _ember['default'].Component;
  var run = _ember['default'].run;
  var isNone = _ember['default'].isNone;
  var getTypeOf = _ember['default'].typeOf;

  /**
   * An input field to choose a time in a day.
   * The user can either enter a time directly, or choose from a list.
   *
   * @namespace EmberDateComponents.Component
   * @class TimePicker
   * @extends Ember.Component
   * @public
   */
  exports['default'] = Component.extend({
    layout: _emberDateComponentsTemplatesComponentsTimePicker['default'],

    classNames: ['time-picker__wrapper'],
    classNameBindings: ['isOpen:time-picker__wrapper--open'],

    /**
     * The current value of the time picker.
     * Has to be a moment.js object or null.
     *
     * @attribute value
     * @type {Date}
     * @public
     */
    value: null,

    /**
     * If this is true, the time picker is disabled and the selected time cannot be changed.
     *
     * @attribute disabled
     * @type {Boolean}
     * @default false
     * @public
     */
    disabled: false,

    /**
     * The action to call when the time changes.
     *
     * @event action
     * @param {Date} time The new time
     * @public
     */
    action: null,

    /**
     * If the display format should use am/pm or the 24:00 format.
     * By default, this will be determined by checking the localized date formatting of moment.js.
     * However, if you don't use moment.js-locales, this will always return true (because the default locale, 'en', uses am/pm).
     * In such a case, you can just overwrite this.
     *
     * @attribute amPm
     * @type {Boolean}
     * @public
     */
    amPm: (0, _emberComputed['default'])(function () {
      return (0, _moment['default'])().startOf('day').format('LLL').toLowerCase().indexOf('am') > -1;
    }),

    /**
     * The minimum time which can be selected.
     * This should be either a parseable string or a moment.js object.
     *
     * @attribute minTime
     * @type {String|Object}
     * @default '00:00'
     * @public
     */
    minTime: '00:00',

    /**
     * The maxmimum time which can be selected.
     * This should be either a parseable string or a moment.js object.
     *
     * @attribute minTime
     * @type {String|Object}
     * @default '23:59'
     * @public
     */
    maxTime: '23:59',

    /**
     * The placeholder for the time input.
     *
     * @attribute placeholder
     * @type {String}
     * @public
     */
    placeholder: 'Enter time...',

    /**
     * The step in minutes which can be selected.
     * Entered times will be rounded to this accuracy.
     * If you don't specify a selectStep separately, this value will also be used as selectStep.
     *
     * @attribute step
     * @type {Number}
     * @default 30
     * @public
     */
    step: 30,

    /**
     * The step from which dates can be selected in the dropdown.
     * If this is not explicitly set, step will be used for this value as well.
     * However, if values like 22:14 should be allowed but not shown in the dropdown,
     * this can be set to a different value.
     *
     * @attribute selectStep
     * @type {Number}
     * @default 30
     * @public
     */
    selectStep: null,

    /**
     * Classes which should be added to the input.
     *
     * @attribute inputClasses
     * @type {String}
     * @public
     */
    inputClasses: '',

    /**
     * Classes which should be added to the dropdown container.
     *
     * @attribute dropdownClasses
     * @type {String}
     * @public
     */
    dropdownClasses: '',

    /**
     * If the dropdown is open.
     *
     * @property isOpen
     * @type {Boolean}
     * @protected
     */
    isOpen: false,

    /**
     * Which option is currently selected.
     * If -1, no option is selected.
     *
     * @property selected
     * @type {Number}
     * @protected
     */
    selected: -1,

    /**
     * The general options for this component.
     * These are built from the single attributes, but you could theoretically overwrite this if you need custom behaviour.
     * The options should always be fetched via this object.
     *
     * @property options
     * @type {amPm, step, minTime, maxTime}
     * @protected
     */
    options: (0, _emberComputed['default'])(function () {
      var amPm = get(this, 'amPm');
      var minTime = get(this, 'minTime');
      var maxTime = get(this, 'maxTime');
      var step = get(this, 'step');
      var selectStep = get(this, 'selectStep');

      return {
        amPm: amPm,
        step: step,
        selectStep: selectStep,
        minTime: (0, _emberDateComponentsUtilsParseTime['default'])(minTime),
        maxTime: (0, _emberDateComponentsUtilsParseTime['default'])(maxTime)
      };
    }),

    /**
     * The internal value.
     * This is used to avoid two-way databinding.
     *
     * @property _value
     * @type {Object|null}
     * @private
     */
    _value: null,

    /**
     * The internal string representation of the value, e.g. the formatted value.
     *
     * @property stringValue
     * @type {String}
     * @protected
     */
    stringValue: null,

    /**
     * The value that is currently entered in the input field.
     *
     * @property inputValue
     * @type {String}
     * @protected
     */
    inputValue: null,

    /**
     * The value which is currently displayed.
     * This is either inputValue, if it is not null, or else stringValue.
     *
     * @property displayValue
     * @type {String}
     * @protected
     */
    displayValue: (0, _emberComputed['default'])('inputValue', 'stringValue', function () {
      var inputValue = get(this, 'inputValue');
      var value = get(this, 'stringValue');
      return isNone(inputValue) ? value : inputValue;
    }),

    /**
     * The format which should be used.
     * By default, this is computed via the amPm setting.
     * You can overwrite this if necessary.
     *
     * @property format
     * @type {String}
     * @protected
     */
    format: (0, _emberComputed['default'])('options.amPm', function () {
      var _get = get(this, 'options');

      var amPm = _get.amPm;

      return amPm ? 'hh:mm a' : 'HH:mm';
    }),

    /**
     * The options to chose from in the dropdown.
     *
     * @property timeOptions
     * @type {Object[]}
     * @readOnly
     * @protected
     */
    timeOptions: (0, _emberComputed['default'])('options.minTime', 'options.maxTime', 'options.selectStep', function () {
      var _get2 = get(this, 'options');

      var minTime = _get2.minTime;
      var maxTime = _get2.maxTime;
      var selectStep = _get2.selectStep;

      var format = get(this, 'format');

      var steps = (0, _emberDateComponentsUtilsBuildTimeRange['default'])({
        minTime: minTime,
        maxTime: maxTime,
        step: selectStep
      });

      return steps.map(function (time) {
        return {
          value: time.format(format),
          time: time
        };
      });
    }),

    /**
     * The options for the dropdown which are currently visible.
     * This filters the timeOptions by the inputValue.
     *
     * @property filteredOptions
     * @type {Object[]}
     * @readOnly
     * @protected
     */
    filteredOptions: (0, _emberComputed['default'])('timeOptions.[]', 'inputValue', function () {
      var val = (get(this, 'inputValue') || '').toLowerCase();
      var options = get(this, 'timeOptions');

      return options.filter(function (option) {
        var optionValue = get(option, 'value');
        return optionValue.toLowerCase().indexOf(val) > -1;
      });
    }),

    /**
     * Open the dropdown.
     *
     * @method _open
     * @private
     */
    _open: function _open() {
      set(this, 'isOpen', true);
    },

    /**
     * Close the dropdown.
     *
     * @method _close
     * @private
     */
    _close: function _close() {
      set(this, 'isOpen', false);
      this._reset();
    },

    /**
     * Reset the temporary values.
     *
     * @method _reset
     * @private
     */
    _reset: function _reset() {
      set(this, 'selected', -1);
      set(this, 'inputValue', null);

      // Set value correctly
      this._initValue();
    },

    /**
     * Check new value..
     * If they have changed, send the action & set them on the component.
     *
     * @method _checkNewValue
     * @param {Object} newValue A new moment.js object
     * @private
     */
    _checkNewValue: function _checkNewValue(newValue) {
      if (newValue !== get(this, '_value')) {
        set(this, '_value', newValue);
        this._sendAction();
      }
    },

    /**
     * Check stringValue and generate the new value from it.
     *
     * @method _checkInput
     * @private
     */
    _checkInput: function _checkInput() {
      var value = (get(this, 'stringValue') || '').toLowerCase();
      var newValue = (0, _emberDateComponentsUtilsParseTime['default'])(value);
      this._checkNewValue(newValue);
    },

    /**
     * Check the inputValue as string and generate the new value from it.
     *
     * @method _checkStringInput
     * @private
     */
    _checkStringInput: function _checkStringInput() {
      var inputValue = get(this, 'inputValue');
      var newValue = (0, _emberDateComponentsUtilsParseTime['default'])(inputValue);

      if (!newValue) {
        set(this, 'stringValue', null);
        this._checkNewValue();
        return;
      }

      var format = get(this, 'format');
      var time = this._normalizeTime(newValue);

      var value = time.format(format);

      set(this, 'stringValue', value);
      this._checkInput();
    },

    /**
     * Takes a moment.js object and normalizes it to the nearest step.
     *
     * @method _normalizeTime
     * @param time
     * @param step
     * @returns {*}
     * @private
     */
    _normalizeTime: function _normalizeTime(time) {
      var _get3 = get(this, 'options');

      var minTime = _get3.minTime;
      var maxTime = _get3.maxTime;
      var step = _get3.step;

      var min = minTime ? minTime.valueOf() : null;
      var max = maxTime ? maxTime.valueOf() : null;
      step = !isNone(step) ? step : 30;
      var val = time ? time.valueOf() : null;

      // if time is before minTime, return minTime
      if (!isNone(min) && val < min) {
        return (0, _moment['default'])(min);
      }

      // if time is after maxTime, return maxTime
      if (!isNone(max) && val > max) {
        return (0, _moment['default'])(max);
      }

      // if time is not in step range, round it up/down
      var stepMs = step * 60 * 1000;
      var diff = val % stepMs;
      if (diff !== 0) {
        // If diff > 50%, round up, elese round down
        if (diff * 2 > stepMs) {
          return (0, _moment['default'])(val + stepMs - diff);
        } else {
          return (0, _moment['default'])(val - diff);
        }
      }

      return time;
    },

    /**
     * Send the action.
     * The action receives a moment.js object or null as parameter.
     *
     * @method _sendAction
     * @private
     */
    _sendAction: function _sendAction() {
      var value = get(this, '_value') || null;
      var action = get(this, 'action');

      if (action && !get(this, 'disabled')) {
        action(value);
      }

      this._close();
    },

    /**
     * Initialise stringValue from value.
     * This is called on reset and when the value changes from outside.
     *
     * @method _initValue
     * @private
     */
    _initValue: function _initValue() {
      var value = get(this, '_value');
      var format = get(this, 'format');

      value = (0, _emberDateComponentsUtilsParseTime['default'])(value);
      value = getTypeOf(value) === 'instance' ? value.format(format) : value;
      set(this, 'stringValue', value || null);
    },

    /**
     * Prepare data for the time input.
     *
     * @method didReceiveAttrs
     * @protected
     * @override
     */
    didReceiveAttrs: function didReceiveAttrs() {
      // Set selectStep to step
      var step = get(this, 'step');
      if (!get(this, 'selectStep')) {
        set(this, 'selectStep', step);
      }

      // Set the internal value
      set(this, '_value', get(this, 'value'));
      this._initValue();
    },

    actions: {

      open: function open() {
        this._open();
      },

      openAndClear: function openAndClear() {
        set(this, 'isOpen', true);
        set(this, 'stringValue', null);
      },

      close: function close() {
        this._close();
      },

      closeNext: function closeNext() {
        var _this = this;

        // Wait for all other events to finish
        // Somehow, 1 or 10 doesn't work
        run.later(this, function () {
          var inputValue = get(_this, 'inputValue');
          // If there is an input, this means it hasn't been processed yet
          // --> Process it now!
          if (inputValue) {
            _this._checkStringInput();
          }

          _this._close();
        }, 100);
      },

      selectUp: function selectUp() {
        this.decrementProperty('selected');
        if (get(this, 'selected') < -1) {
          set(this, 'selected', -1);
        }
      },

      selectDown: function selectDown() {
        this.incrementProperty('selected');
        var optionsLength = get(this, 'filteredOptions.length');

        if (get(this, 'selected') > optionsLength) {
          set(this, 'selected', optionsLength - 1);
        }
      },

      selectCurrent: function selectCurrent() {
        var options = get(this, 'filteredOptions');
        var selected = get(this, 'selected');

        // If nothing is selected, simply try to parse the entered string
        if (selected === -1) {
          this._checkStringInput();
          return;
        }

        var selectedOption = options[selected];

        // If, for whatever reason, the selected options doesn't exist
        // Just parse the string - this should't happen, normally
        if (!selectedOption) {
          this._checkStringInput();
          return;
        }

        // Actually set stringValue and check the input
        var value = get(selectedOption, 'value');
        set(this, 'stringValue', value);
        this._checkInput();
      },

      selectValue: function selectValue(value) {
        set(this, 'stringValue', value);
        this._checkInput();
      },

      updateInputValue: function updateInputValue(val) {
        // Always open the select box when someone starts to type
        this._open();
        set(this, 'inputValue', val);
      }
    }
  });
});