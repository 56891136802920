define('ember-date-components/utils/build-time-range', ['exports', 'ember', 'ember-date-components/utils/parse-time'], function (exports, _ember, _emberDateComponentsUtilsParseTime) {
  exports.buildTimeRange = buildTimeRange;
  var isNone = _ember['default'].isNone;

  /**
   * Build a time range.
   * This will return an array of moment.js objects with corresponding times set.
   * minTime/maxTime will be used to define the boundaries of the range.
   * They can either be parseable strings, or moment.js objects.
   * Steps should be the number of minutes between the different steps, e.g. 30 or 60.
   *
   * @namespace EmberDateComponents.Utils
   * @method buildTimeRange
   * @param {String|Object} minTime The time where the range should begin. Defaults to '00:00'
   * @param {String|Object} maxTime The time where the range should end. Defaults to '23:59'
   * @param {Number} step The number of minutes between the steps. Defaults to 30.
   * @return {Object[]} An array of moment.js objects
   * @public
   */

  function buildTimeRange(_ref) {
    var minTime = _ref.minTime;
    var maxTime = _ref.maxTime;
    var step = _ref.step;

    var min = !isNone(minTime) ? minTime : '00:00';
    var max = !isNone(maxTime) ? maxTime : '23:59';
    step = !isNone(step) ? step : 30;

    min = (0, _emberDateComponentsUtilsParseTime['default'])(min);
    max = (0, _emberDateComponentsUtilsParseTime['default'])(max);

    var lastValue = min;
    var steps = [];

    while (lastValue.date() === max.date() && (lastValue.hours() < max.hours() || lastValue.hours() === max.hours() && lastValue.minutes() <= max.minutes())) {
      steps.push(lastValue);
      var newTime = lastValue.clone().add(step, 'minutes');
      lastValue = newTime;
    }

    return steps;
  }

  exports['default'] = buildTimeRange;
});