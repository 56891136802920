define('ember-date-components/helpers/date-picker-day-classes', ['exports', 'ember'], function (exports, _ember) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  exports.datePickerDayClasses = datePickerDayClasses;
  var Helper = _ember['default'].Helper;

  function datePickerDayClasses(_ref, _ref3) {
    var _ref2 = _slicedToArray(_ref, 1);

    var baseClass = _ref2[0];
    var _ref3$isToday = _ref3.isToday;
    var isToday = _ref3$isToday === undefined ? false : _ref3$isToday;
    var _ref3$isSelected = _ref3.isSelected;
    var isSelected = _ref3$isSelected === undefined ? false : _ref3$isSelected;
    var _ref3$isDisabled = _ref3.isDisabled;
    var isDisabled = _ref3$isDisabled === undefined ? false : _ref3$isDisabled;
    var _ref3$isInRange = _ref3.isInRange;
    var isInRange = _ref3$isInRange === undefined ? false : _ref3$isInRange;

    var isTodayClass = isToday ? ' ' + baseClass + '--today' : '';
    var isSelectedClass = isSelected ? ' ' + baseClass + '--selected' : '';
    var isDisabledClass = isDisabled ? ' ' + baseClass + '--disabled' : '';
    var isInRangeClass = isInRange ? ' ' + baseClass + '--in-range' : '';

    return '' + baseClass + isTodayClass + isSelectedClass + isDisabledClass + isInRangeClass;
  }

  exports['default'] = Helper.helper(datePickerDayClasses);
});